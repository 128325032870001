import {
    authUserAction,
    storeUserDetailsAction,
    saveAccessTokenAction,
    errorMessageAction,
    saveUserIdAction,
} from "../Redux/Actions/actions";
//import { baseApiURL } from "../Utilities/utility";
let gID,
    generatedToken = "";

const baseApiURL = window.location.origin;

export function authenticateUserBasedOnValidToken(dispatch, newToken, gID) {
    var formdataPart1 = new FormData();
    formdataPart1.append("Token", newToken);


    const options = {
        method: "post",
        processData: false,
        mimeType: "multipart/form-data",
        contentType: false,
        body: formdataPart1,
    };

    return fetch(baseApiURL + "/api/Login/AuthenticateGId", options)
        .then((response) => response.json())
        .then((data) => {
            if (data?.result?.response == false) {
                console.log("Unauthorised");
                var userDataNull = {
                    userData: {
                        gid: "",
                        roleId: 0,
                        roleName: "",
                        userEmail: "",
                        userFirstName: "",
                        userLastName: "",
                        userId: 0,
                        respMsg: data?.result?.responseMsg
                    },
                };
                dispatch(storeUserDetailsAction(userDataNull));
                return true;
            } else {
                if (typeof data.result.response == "string") {
                    var userDataNull = {
                        userData: {
                            gid: "",
                            roleId: 0,
                            roleName: "",
                            userEmail: "",
                            userFirstName: "",
                            userLastName: "",
                            userId: 0,
                            respMsg: ""
                        },
                    };
                    dispatch(storeUserDetailsAction(userDataNull));
                    return true;
                } else if (data.result.response.roleId != null) {
                    dispatch(saveUserIdAction(data.result.uid));
                    dispatch(storeUserDetailsAction(data.result.response));
                    dispatch(saveAccessTokenAction(data.result.tokenString));
                    window.localStorage.setItem(
                        "response",
                        JSON.stringify(data.result.response)
                    );
                    return true;
                } else if (data?.result?.response?.roleId == null) {
                    var userDataNull = {
                        gid: null,
                        roleId: null,
                        roleName: null,
                        userEmail: null,
                        userFirstName: null,
                        userLastName: null,
                        userId: null,
                    };
                    dispatch(storeUserDetailsAction(userDataNull));
                    return false;
                } else {
                    return false;
                }
            }
        })
        .catch((error) => {
            return false;
        });
}

// We are getting ONLY token from this api
export function validateTheUserBasedOnApi(dispatch, auth) {
    const arr = auth.user.profile.sub.split("|");
    let gid = arr && arr.length ? arr[1] : "";
    const systemUser = {
        GID: gid,
    };
    const options = {
        method: "post",
        mode: "cors",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": true,
            "Access-Control-Allow-Origin": `${window.location.origin}`,
        },
        body: JSON.stringify(systemUser),
    };

    return fetch(baseApiURL + "/api/Login/ValidateRequest", options)
        .then((res) => res.json())
        .then((res_token) => {
            if (auth !== undefined && auth.user) {
                const arr = auth.user.profile.sub.split("|");
                gID = arr && arr.length ? arr[1] : "";
                dispatch(authUserAction(gID));
            }
            generatedToken = res_token.result.objResult;
            dispatch(saveAccessTokenAction(generatedToken));

            return generatedToken;
        })
        .catch((error) =>
            dispatch(errorMessageAction("Invalid Email or Password"))
        );
}
