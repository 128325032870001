import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "./assets/breakpoint.scss";
import "./App.css";
import { LoginRoute } from "./Routes/LoginRoute";
import { useAuth } from "react-oidc-context";
import { useDispatch, useSelector } from "react-redux";
import { authUserAction, storeUserDetailsAction } from "../src/Redux/Actions/actions";
import { CommercialRoute, EngineerRoute, MasterRoute, OtherRoute, RMRoute } from "./Routes/MasterRoute";
import Header from "./Components/Header/Header";
import Loader from "./Components/Loader/Loader";
import {
    authenticateUserBasedOnValidToken,
    validateTheUserBasedOnApi,
} from "../src/APIs/api_Login";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import UnauthorizedAccess from "./Components/UnauthorizedAccess/UnauthorizedAccess";
import IdleTimer from "react-idle-timer";
import { saveAccessTokenAction, saveAzureTokenAction } from "../src/Redux/Actions/actions";
import { useMsal } from '@azure/msal-react';
export default function App() {
    const auth = useAuth();
    const dispatch = useDispatch();
    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );

    const [isLoader, setIsLoader] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [unAuthText, setUnAuthText] = useState("");


    const handleClose = (e, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        setDialogOpen(false);
    };
    const { instance, accounts } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const [isAuthorized, setIsAuthorized] = useState(true);
    //function sessionExpire(auth, dispatch) {
    //  var newurl =
    //    window.location.protocol +
    //    "//" +
    //    window.location.host +
    //    window.location.pathname;
    //  window.history.pushState({ path: newurl }, "", `/`);
    //  localStorage.clear();
    //  sessionStorage.clear();
    //  if (auth !== undefined) {
    //    dispatch(storeUserDetailsAction(""));

    //    caches.keys().then((names) => {
    //      names.forEach((name) => {
    //        caches.delete(name);
    //      });
    //    });
    //  }
    //  window.location.href = "/";
    //  }
    function authenticateUserWithGID(gid) {
        authenticateUserBasedOnValidToken(dispatch, activeAccount.idToken, gid)
            .then((response) => {
                if (response) {
                    console.log("app", response)
                    // dispatch(saveAccessTokenAction(activeAccount.idToken));
                    setIsLoader(false);
                    setIsAuthorized(false);
                } else {
                    setIsLoader(false);
                    setIsAuthorized(false);
                    setDialogOpen(true);
                }
            })
            .catch((error) => {
                //setDialogOpen(true);
                setIsLoader(false);
            });
    }


    //useEffect(() => {
    //  if (auth.user) {
    //    setIsLoader(true);
    //    if (token) {
    //      const arr = auth.user.profile.sub.split("|");
    //      let gid = arr && arr.length ? arr[1] : "";
    //      authenticateUserBasedOnValidToken(dispatch, token, gid);
    //      // console.log('userDetails', userDetails);
    //      if (userDetails.userId !== 0) setisAuthorized(true);
    //      setIsLoader(false);
    //    } else {
    //      validateTheUserBasedOnApi(dispatch, auth)
    //        .then((response) => {
    //          setIsLoader(false);
    //        })
    //        .catch((error) => {
    //          setIsLoader(false);
    //          setisAuthorized(false);
    //        });

    //      //validateTheUserBasedOnApi(dispatch, auth);
    //    }
    //  }
    //}, [auth, token, dispatch]);
    useEffect(() => {
        console.log(activeAccount);
        if (activeAccount && isAuthorized && !userDetails.userId) {
            setIsLoader(true);
            dispatch(saveAccessTokenAction(activeAccount.idToken));
            dispatch(saveAzureTokenAction(activeAccount.idToken));
            authenticateUserWithGID();
        }
        else {

            setIsLoader(false);

        }


    }, [instance, activeAccount, !userDetails.userId]);
    //useEffect(() => {
    //    console.log(activeAccount);
    //    if (activeAccount) {
    //        setIsLoader(true);
    //        if (activeAccount) {
    //            //const arr = auth.user.profile.sub.split("|");
    //            let gid = accounts[0].idTokenClaims.gid;
    //            dispatch(authUserAction(window.btoa(gid)));
    //            dispatch(saveAccessTokenAction(activeAccount.idToken));
    //            authenticateUserWithGID(window.btoa(gid));
    //        }
    //        else {
    //            validateTheUserBasedOnApi(dispatch, accounts[0].idTokenClaims.gid)
    //                .then((response) => {
    //                    setIsLoader(false);
    //                })
    //                .catch((error) => {
    //                    setIsLoader(false);
    //                });
    //        }
    //    }
    //    else {
    //        setIsLoader(false);
    //    }
    //}, [accounts]);


    //function logoutClick() {
    //  dispatch(saveAccessTokenAction(null));
    //  auth.signoutRedirect();
    //  window.location = "https://myid.siemens.com/idp/startSLO.ping";
    //}

    return (
        <>
            <BrowserRouter>
                <div className="ssiat-master-block">
                    {auth.isLoading || isLoader ? (
                        <Loader />
                    ) : (userDetails && userDetails.roleId > 0) || auth.user ? (
                        <>
                            <Header>Header Component</Header>
                            <main className="main-component">{userDetails.roleId === 1 ? MasterRoute :
                                userDetails.roleId === 2 ? EngineerRoute : userDetails.roleId === 3 ? RMRoute : userDetails.roleId === 4 ? CommercialRoute : OtherRoute}</main>
                        </>
                        ) : (userDetails && userDetails?.userData?.respMsg?.length > 0) ? OtherRoute :(
                        LoginRoute
                    )}
                </div>
                {/*<div>*/}
                {/*    <IdleTimer*/}
                {/*        timeout={1000 * 60 * 15}*/}
                {/*        onIdle={sessionExpire}*/}
                {/*        debounce={250}*/}
                {/*    />*/}
                {/*</div>*/}
                {/*<>*/}
                {/*    <Dialog*/}
                {/*        disableEscapeKeyDown*/}
                {/*        open={dialogOpen}*/}
                {/*        onClose={handleClose}*/}
                {/*        aria-labelledby="alert-dialog-title"*/}
                {/*        aria-describedby="alert-dialog-description"*/}
                {/*    >*/}
                {/*        <DialogTitle id="alert-dialog-title">*/}
                {/*            {auth.error ? "MyID Session Expired" : "Un-Authorized"}*/}
                {/*        </DialogTitle>*/}
                {/*        <DialogContent>*/}
                {/*            <UnauthorizedAccess*/}
                {/*                errorText={*/}
                {/*                    auth.error ? "MyID Error :" + auth.error?.stack : unAuthText*/}
                {/*                }*/}
                {/*            />*/}
                {/*        </DialogContent>*/}
                {/*    </Dialog>*/}
                {/*</>*/}
            </BrowserRouter>
        </>
    );
}
