import React, { useState, useEffect } from "react";
import axios from 'axios';
import {
    Grid,
    Button,
    MenuItem,
    Select,
    TextField,
    FormControl,
    FormHelperText,
    Tooltip,
    ListItemText,
    Typography,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    DialogContent,

    IconButton,
} from "@material-ui/core";
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import InputLabel from "@material-ui/core/InputLabel";
import { BootstrapInput } from "../../../Utilities/Utility";
import { DeleteOutlined } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import MaterialTable from "material-table";
import { useSelector } from "react-redux";
import SnackbarCustom from '../../../Components/Snackbar/Snackbar';
import { tableIcons } from "../../../Utilities/Utility";
import { tableIconsInputScreen } from "../../../Utilities/Utility";
import { tableOptionsUser } from "../../../Themes/LightTheme";
import { getAllUser, getAllOffice, getAllRegion, getAllRoll, getAllValue, DeleteUser, UpdateUser, SaveUser, getAllRole } from "../../../APIs/api_Insert";
import Loader from "../../../Components/Loader/Loader";
import {
    GetAddButton,

} from "../../../Utilities/Utility";

export default function UserManagement() {

    const api = axios.create({
        baseURL: 'api/UserManagement'
    })
    const apiRole = axios.create({
        baseURL: 'Role'
    })
    const token = useSelector((state) => state.saveTokenReducer.token);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );

    //For retriving data
    const [data, setData] = useState([]);
    const [tableData, setTableData] = useState([]);

    //for role dropdown
    const [itemsRole, setItemsRole] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    //for snackBar
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    // for dropdowns
    const [office, setOffice] = useState("")
    const [officeData, setOfficeData] = useState([])

    const [region, setRegion] = useState("")
    const [regionData, setRegionData] = useState([])

    const [value, setValue] = useState("")
    const [valueData, setValueData] = useState([])

    const [roll, setRoll] = useState("")
    const [rollData, setRollData] = useState([])
    // textfields
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [gid, setGid] = useState("")
    const [role, setRole] = useState("")
    const [number, setNumber] = useState("")
    const [rollNo, setRollNo] = useState(null)
    const [contact, setContact] = useState(null)
    const [id, setId] = useState(null)
    const [gidDisable, setGidDisable] = useState(false)
    function handleChangeFirstName(e) {
        const enteredVal = e.target.value;
        if (isValidNameField(enteredVal)) {
            setFirstName(enteredVal);
            setFirstNameHelper("");
        } else {
            setFirstNameHelper("Enter Valid First Name");
            setFirstName(enteredVal);
        }
    }
    function isValidNameField(name) {
        const re = /^[a-zA-Z ]*$/;
        return re.test(name);
    }
    function handleChangeLastName(e) {
        setLastName(e.target.value);
    }
    function handleChangeEmail(e) {
        setEmail(e.target.value);
    }
    function handleChangeGid(e) {
        setGid(e.target.value);
    }
    function handleChangeRole(e) {
        setRole(e.target.value);
    }
    function handleChangeRollNo(e) {
        setRollNo(e.target.value);
    }
    function handleChangeContact(e) {
        setContact(e.target.value);
    }
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = (e) => {
        setOpen(false);
    };
    function handleChangeOffice(e) {
        setOffice(e.target.value);
    }
    function handleChangeRegion(e) {
        setRegion(e.target.value);
    }
    function handleChangeValue(e) {
        setValue(e.target.value);
    }
    function handleChangeRoll(e) {
        setRoll(e.target.value);
    }
    const staticData = [{
        id: 1,
        firstName: 'FirstName1',
        lastName: 'LastName1',
        emailId: 'test@gmail.com',
        location: 'Hyderabad',
        roleId: 'Admin',
        gid: 'z004bxrf',
        role: { roleName: 'Admin' },
        roleId: 1,
    },
    {
        id: 2,
        firstName: 'FirstName2',
        lastName: 'LastName2',
        emailId: 'test2@gmail.com',
        location: 'Hyderabad',
        roleId: 'Admin',
        gid: 'z004bxrf',
        role: { roleName: 'User' },
        roleId: 3,
    },
    {
        id: 3,
        firstName: 'FirstName3',
        lastName: 'LastName33',
        emailId: 'test3@gmail.com',
        location: 'Hyderabad',
        roleId: 'Admin',
        gid: 'z004bxrf',
        role: { roleName: 'External' },
        roleId: 2,
    },
    {
        id: 4,
        firstName: 'FirstName4',
        lastName: 'LastName4',
        emailId: 'test4@gmail.com',
        location: 'Hyderabad',
        roleId: 'Admin',
        gid: 'z004bxrf',
        role: { roleName: 'Admin' },
        roleId: 1,
    }];
    const [openDia, setOpenDia] = useState(false);
    const handleCloseDia = () => {
        setOpenDia(false);
        clear()
    }
    const date = new Date();
    const isoString = date.toISOString();
    const [dialogSubmitBtn, setDialogSubmitBtn] = useState("Save");
    const [custDialogTitle, setCustDialogTitle] = useState("Add New User");
    const [firstNameHelper, setFirstNameHelper] = useState("*required");
    const [lastNameHelper, setLastNameHelper] = useState("*required");
    const [gidHelper, setGIDHelper] = useState("*required");
    const [emailHelper, setEmailHelper] = useState("*required");
    const [roleHelper, setRoleHelper] = useState("*required");
    //Representing table headers
    //var columns = [
    //    { title: "id", field: "id", hidden: true },
    //    {
    //        title: 'Name',
    //        field: 'firstName',
    //        validate: (rowData) => validateRequiredField(rowData, rowData.firstName, 'firstName'),
    //    },
    //    {
    //        title: 'LastName',
    //        field: 'lastName',
    //        validate: (rowData) => validateRequiredField(rowData, rowData.lastName, 'lastName'),
    //    },
    //    {
    //        title: 'Location',
    //        field: 'location',
    //        validate: (rowData) => validateRequiredField(rowData, rowData.location, 'location'),
    //    },
    //    {
    //        title: "Role",
    //        field: "roleId",
    //        render: (rowData) => <span>{renderRoleValue(rowData.roleId)}</span>,
    //        editComponent: (props) => getRoleDDLComponent(props),
    //        validate: (rowData) => validateRole(rowData.roleId),
    //        customFilterAndSearch: (term, rowData) =>
    //            renderRoleValue(rowData.roleId)
    //                .toLowerCase()
    //                .includes(term.toLowerCase()),
    //    },
    //    {
    //        title: 'Email Id',
    //        field: 'emailId',
    //        validate: (rowData) => validateRequiredField(rowData, rowData.emailId, 'emailId'),
    //    },
    //    {
    //        title: 'GID',
    //        field: 'gid',
    //        validate: (rowData) => rowData.roleId == 1 ? validateRequiredField(rowData, rowData.gid, 'gid') : "",
    //    }
    //];

    var col = [
        { title: "id", field: "id", hidden: true },
        {
            title: 'First Name',
            field: 'userFirstName',
            validate: (rowData) => validateAlphabet(rowData.userFirstName),
           
        },
        {
            title: 'Last Name',
            field: 'userLastName',
            validate: (rowData) => validateAlphabet(rowData.userLastName),
        },
        {
            title: 'E-mail',
            field: 'userEmail',

        },
        {
            title: 'GID',
            field: 'gid',

        },
        {
            title: 'Role',
            field: 'roleName',

        },
        
        
        //{
        //    title: 'ROLL',
        //    field: 'rollName',

        //},
       
        
        
        {
            title: 'Value',
            field: 'valueName',

        },

        {
            title: 'Office',
            field: 'officeName',

        },
        {
            title: 'Region',
            field: 'regionName',

        },
        //{
        //    title: 'Roll No',
        //    field: 'rollNumber',

        //},
        {
            title: 'ContactNumber',
            field: 'contactNumber',

        },
    ]
    function validateAlphabet(value) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : !isValidNameField(value)
                ? { isValid: false, helperText: "Only alphabets" }
                : { isValid: true, helperText: "" };
    }
    function onRoleChange(e, objProps) {
        objProps.onChange(e.target.value);
        // objProps.rowData?.gid = null;
    }
    function GetEditButton() {
        return (
            <Tooltip title="Edit" placement="bottom">
                <span>
                    <Button href="javascript:void(0)">
                        <span>
                            <EditOutlinedIcon fontSize="small" className="attachFileIcon" />
                        </span>
                    </Button>
                </span>
            </Tooltip>
        );
    }
    function getRoleDDLComponent(objProps) {
        return (
            <FormControl error={!objProps.value ? true : false} fullWidth>
                <Select
                    value={objProps.value}
                    onChange={(e) => {
                        onRoleChange(e, objProps);
                    }}
                >
                    {itemsRole.map((item, index) => (
                        <MenuItem key={index} value={item.value}>
                            {item.label}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText>{!objProps.value ? "*Required" : ""}</FormHelperText>
            </FormControl>
        );
    }
    function renderRoleValue(value) {
        let obj = {};
        if (value === 6) {
            return "Internal user";
        } else {
            if (itemsRole && itemsRole.length > 0) {
                obj = itemsRole.find((c) => c.value == value);
            }
            if (obj) {
                return obj.label;
            } else {
                return "";
            }
        }
    }
    function validateRole(value) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : { isValid: true, helperText: "" };
    }

    useEffect(() => {
        // setTableData(staticData);
        getRoles();
        getUsers();
        getOffice();
        getRegion();
        getRoll();
        getValue();


    }, []);
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    function getUsers() {
        setIsLoading(true);

        getAllUser(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId
        )
            .then((response) => {
                setIsLoading(false);

                console.log("hicustomer", response);
                //console.log(response.objResult);
                setData(response);
            })
            .catch((error) => {
                console.log(error.response);
                console.log("Error in hitting api at page load.", error);
                setIsLoading(false);
            });
    }
    function getOffice() {
        //setIsLoading(true);

        getAllOffice(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                //setIsLoading(false);

                console.log("hicustomer", response);
                //console.log(response.objResult);
                setOfficeData(response);
            })
            .catch((error) => {
                console.log(error.response);
                console.log("Error in hitting api at page load.", error);
                //setIsLoading(false);
            });
    }
    function getRegion() {
        //setIsLoading(true);

        getAllRegion(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                //setIsLoading(false);

                console.log("hicustomer", response);
                //console.log(response.objResult);
                setRegionData(response);
            })
            .catch((error) => {
                console.log(error.response);
                console.log("Error in hitting api at page load.", error);
                //setIsLoading(false);
            });
    }
    function getRoll() {
        //setIsLoading(true);

        getAllRoll(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                //setIsLoading(false);

                console.log("hicustomer", response);
                //console.log(response.objResult);
                setRollData(response);
            })
            .catch((error) => {
                console.log(error.response);
                console.log("Error in hitting api at page load.", error);
                //setIsLoading(false);
            });
    }
    function getValue() {
        //setIsLoading(true);

        getAllValue(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                //setIsLoading(false);

                console.log("hicustomer", response);
                //console.log(response.objResult);
                setValueData(response);
            })
            .catch((error) => {
                console.log(error.response);
                console.log("Error in hitting api at page load.", error);
                //setIsLoading(false);
            });
    }

    const dialogDraftValuesFn = (rowData) => {
        console.log("Row Data", rowData);
        //setDialogSubmitBtn("Update");
        setOpenDia(true);
        setLastNameHelper("")
        setFirstNameHelper("")
        setGIDHelper("")
        setEmailHelper("")
        setRoleHelper("")
        setGidDisable(true)
        //setIsLoading(true);
        setFirstName(rowData.userFirstName)
        setLastName(rowData.userLastName)
        setEmail(rowData.userEmail)
        setGid(rowData.gid)
        setOffice(rowData.office)
        setRegion(rowData.region)
        setValue(rowData.valueId)
        setRoll(rowData.rollId)
        setRole(rowData.roleId)
        setId(rowData.userId)
        setRollNo(rowData.rollNumber)
        setContact(rowData.contactNumber)
    }

    function clear() {
        setFirstName("")
        setLastName("")
        setEmail("")
        setGid("")
        setOffice("")
        setRegion("")
        setValue("")
        setRoll("")
        setRole("")
        setRollNo(null)
        setContact(null)
        setLastNameHelper("")
        setFirstNameHelper("")
        setGIDHelper("")
        setEmailHelper("")
        setRoleHelper("")
        setGidDisable(false)
    }
    const handleSaveForm = () => {
        //setFormDialogTitle("Save");
        //setIsLoading(true);
        if (firstNameHelper != "" ) {
            handleSnackOpen("Check firstName ", "error");
            return;
        }
        if (lastNameHelper != "") {
            handleSnackOpen("Check lastName ", "error");
            return;
        }
        if (gidHelper != "") {
            handleSnackOpen("Check Gid ", "error");
            return;
        }
        if (emailHelper != "") {
            handleSnackOpen("Check Email ", "error");
            return;
        }
        if (firstName === "" || firstName === null || firstName === undefined) {
            handleSnackOpen("firstName is Empty", "error");
            return;
        }
        if( lastName === "" || lastName === null || lastName === undefined) {
            handleSnackOpen("lastName is Empty", "error");
            return;
        }
        if (email === "" || email === null || email=== undefined) {
            handleSnackOpen("email is Empty", "error");
            return;
        }
        if (gid === "" || gid=== null || gid === undefined) {
            handleSnackOpen("gid is Empty", "error");
            return;
        }
        if (role === "" || role === null || role === undefined) {
            handleSnackOpen("role is Empty", "error");
            return;
        }
        if (value === "" || value === null || value === undefined) {
            handleSnackOpen("Value is Empty", "error");
            return;
        }
        if (office === "" || office === null || office=== undefined) {
            handleSnackOpen("Office is Empty", "error");
            return;
        }
        if (region === "" || region === null || region === undefined) {
            handleSnackOpen("Region is Empty", "error");
            return;
        }
        setIsLoading(true);
        let formData = {

            // UserId: userDetails.userId,
            UserFirstName: firstName,
            UserLastName: lastName,
            UserEmail: email,
            Gid: gid,
            RollNumber: 0,
            ContactNumber: contact,
            Region: region,
            RegionName: "null",
            Office: office,
            OfficeName: "null",
            RoleId: role,
            RoleName: "null",
            RollId: 0,
            RollName: "null",
            ValueId: value,
            ValueName: 0,
            IsActive: true,
            CreatedBy: userDetails.userId,
            LoggedInUserId: userDetails.userId,
            LoggedInUser: userDetails.userId,

        }

        SaveUser(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, formData)
            .then((response) => {
                if (response.response) {

                    handleCloseDia()
                    handleSnackOpen(response.responseMsg, "success");
                    setIsLoading(false);
                    //getItemGrid(refId)
                    getUsers()

                } else {

                    handleCloseDia()
                    handleSnackOpen(response.responseMsg, "error");
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                handleCloseDia()

                handleSnackOpen("Exception : " + error, "error");
                setIsLoading(false);
            });

    }
    const handleUpdateForm = () => {
        if (firstNameHelper != "") {
            handleSnackOpen("Check firstName ", "error");
            return;
        }
        if (lastNameHelper != "") {
            handleSnackOpen("Check lastName ", "error");
            return;
        }
        if (gidHelper != "") {
            handleSnackOpen("Check Gid ", "error");
            return;
        }
        if (emailHelper != "") {
            handleSnackOpen("Check Email ", "error");
            return;
        }
        //setFormDialogTitle("Save");
        if (firstName === "" || firstName === null || firstName === undefined) {
            handleSnackOpen("firstName is Empty", "error");
            return;
        }
        if (lastName === "" || lastName === null || lastName === undefined) {
            handleSnackOpen("lastName is Empty", "error");
            return;
        }
        if (email === "" || email === null || email === undefined) {
            handleSnackOpen("email is Empty", "error");
            return;
        }
        if (gid === "" || gid === null || gid === undefined) {
            handleSnackOpen("gid is Empty", "error");
            return;
        }
        if (role === "" || role === null || role === undefined) {
            handleSnackOpen("role is Empty", "error");
            return;
        }
        setIsLoading(true);
        let formData = {

            UserId: id,
            UserFirstName: firstName,
            UserLastName: lastName,
            UserEmail: email,
            RollNumber: 0,
            ContactNumber: contact,
            Gid: gid,
            Region: region,
            RegionName: "null",
            Office: office,
            OfficeName: "null",
            RoleId: role,
            RoleName: "null",
            RollId: 0,
            RollName: "null",
            ValueId: value,
            ValueName: 0,
            IsActive: true,
            CreatedBy: userDetails.userId,
            ModifiedBy: userDetails.userId,
            LoggedInUserId: userDetails.userId,
            LoggedInUser: userDetails.userId
            //ModifiedDate: date,
        }

        UpdateUser(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId,formData)
            .then((response) => {
                if (response.response) {

                    handleCloseDia()
                    handleSnackOpen(response.responseMsg, "success");
                    setIsLoading(false);
                    //getItemGrid(refId)
                    getUsers()

                } else {

                    handleCloseDia()
                    handleSnackOpen(response.responseMsg, "error");
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                handleCloseDia()

                handleSnackOpen("Exception : " + error, "error");
                setIsLoading(false);
            });

    }

    //const getRoles = async () => {
    //    apiRole.get('/getAllRoles')
    //        .then(res => {
    //            setItemsRole(
    //                res.data.map(({ roleName, id }) => ({ label: roleName, value: id }))
    //            );
    //        })
    //        .catch(error => {
    //            console.log("Error in hitting api at page load.")
    //        })
    //}
    function getRoles() {
        setIsLoading(true);

        getAllRole(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                setIsLoading(false);

                console.log("hicustomer", response);
                //console.log(response.objResult);
                setItemsRole(response.objResult);
            })
            .catch((error) => {
                console.log(error.response);
                console.log("Error in hitting api at page load.", error);
                setIsLoading(false);
            });
    }
    //Add New User
    const handleRowAdd = (newData, resolve, reject) => {
        //newData.roleId = 1;
        newData.createdBy = userDetails.id;
        api.post('/postUser', newData)
            .then(res => {
                let dataToAdd = [...data];
                if (res.data === 2) {
                    setData([...dataToAdd]);
                    handleSnackOpen("User is already exist.", "warning");
                    reject();
                }
                else {
                    dataToAdd.push(newData);
                    setData(dataToAdd);
                    resolve();
                    getUsers();
                    handleSnackOpen("User added successfully.", "success");
                }
            })
            .catch(error => {
                reject();
            })
    };
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteRowData, setDeleteRowData] = useState(null);
    const handleDeleteIconClick = (rowData) => {
        setDeleteRowData(rowData);
        setDeleteDialogOpen(true);
    };
    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
        setDeleteRowData(null);
    };
//const[helperTextVal,setHelperTextVal]=useState("")
    function validateRequiredField(rowData, value, fieldName) {
        let result = true;
        let requiredVal = true;
        let helperTextVal;
        const maxLength = 50;
        const maxGidLength = 8;
        const nameLength = 100;
        const exp = /^[a-zA-Z]*$/;
        const expGid = /^[\/a-zA-Z0-9]*$/;
        const expEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        const expMailDoamin = /\@(.*?)\./;

        if (fieldName === 'firstName') {
            requiredVal = (!value) ? false : true;
            result = exp.test(value);
            helperTextVal = "Invalid Firstname";
            if (value) {
                if (value.length > nameLength) {
                    result = false;
                    helperTextVal = "First Name should not exceeds the Max length: 100";
                }
            }
        }
        else if (fieldName === 'lastName') {
            requiredVal = (!value) ? false : true;
            result = exp.test(value);
            helperTextVal = "Invalid Lastname";
            if (value) {
                if (value.length > nameLength) {
                    result = false;
                    helperTextVal = "Last Name should not exceeds the Max length: 100";
                }
            }
        }
        else if (fieldName === 'location') {
            requiredVal = (!value) ? false : true;
            result = exp.test(value);
            helperTextVal = "Invalid Location";
            if (value) {
                if (value.length > nameLength) {
                    result = false;
                    helperTextVal = "Location should not exceeds the Max length: 100";
                }
            }
        }
        else if (fieldName === 'emailId') {
            requiredVal = (!value) ? false : true;
            result = expEmail.test(value);
            const domainResult = expMailDoamin.exec(value);
            helperTextVal = "Invalid Emailid";

            if (result && (rowData.roleId == 2 || rowData.roleId == 3) && domainResult[1] == "siemens") {
                result = false;
                helperTextVal = "Invalid Email";
            }
            else if (result == false) {
                result = false;
                helperTextVal = "Invalid Email Format";
            }
            //if (value) {
            //    if (value.length > maxLength) {
            //        result = false;
            //        helperTextVal = "Email Id should not exceeds the Max length: 50";
            //    }
            //}
        }
        else if (fieldName === 'gid') {
            requiredVal = (!value) ? false : true;
            result = expGid.test(value);
            const roleSelected = rowData.roleId;
            helperTextVal = roleSelected != 1 ? "Invalid Gid" : "";
            if (value && roleSelected == 1) {
                if (value.length != maxGidLength) {
                    result = false;
                    helperTextVal = "GID should be of 8 digit";
                }
            }
            else {
                result = true;
                helperTextVal = "";
            }
        }

        return !requiredVal
            ? { isValid: false, helperText: "*Required" }
            : !result
                ? { isValid: false, helperText: helperTextVal }
                : { isValid: true, helperText: "" };
    }

    const onChange = (event) => {
        let valid;
        switch (event.target.id) {
            case "email":
                setEmail(event.target.value);
                // valid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                //     event.target.value
                // );
                const mailValidationExp = (/^\w+([\.-]?\w+)*@siemens.com/);
                valid = mailValidationExp.test(
                    event.target.value
                );
                if (!valid) {
                    setEmailHelper("Invalid Email");
                    //setDisableButton(true);
                } else {
                    setEmailHelper("");
                    //setDisableButton(false);
                }
                break;
            case "gid":
                setGid(event.target.value);
                valid = /^[a-zA-Z][a-zA-Z0-9]*$/.test(event.target.value);
                if (!valid) {
                    setGIDHelper("Cannot start with Number and Special Character");
                    //setDisableButton(true);
                } else if (event.target.value.length !== 8) {
                    setGIDHelper("Minimum 8 characters");
                   // setDisableButton(true);
                } else {
                    setGIDHelper("");
                   // setDisableButton(false);
                }

                break;
            case "firstName":
                setFirstName(event.target.value);
                valid = /^[a-zA-Z ]*$/.test(event.target.value);
                if (!valid) {
                    setFirstNameHelper("Cannot contain special character and numbers");
                   // setDisableButton(true);
                } else if (event.target.value.length === 0) {
                    setFirstNameHelper("Cannot be left blank");
                    //setDisableButton(true);
                } else {
                    setFirstNameHelper("");
                   // setDisableButton(false);
                }
                break;
            case "lastName":
                setLastName(event.target.value);
                valid = /^[a-zA-Z]*$/.test(event.target.value);
                if (!valid) {
                    setLastNameHelper("Cannot contain special character and numbers");
                   // setDisableButton(true);
                } else if (event.target.value.length === 0) {
                    setLastNameHelper("Cannot be left blank");
                    //setDisableButton(true);
                } else {
                    setLastNameHelper("");
                    //setDisableButton(false);
                }
                break;
            case "role":
                setRole(event.target.value);
                if (event.target.value.length === 0) {
                    setRoleHelper("Cannot be left blank");
                    //setDisableButton(true);
                } else {
                    setRoleHelper("");
                    //setDisableButton(false);
                }
                break;

            default:
                break;
        }
    };
    
    function GetDeleteButton() {
        return (
            <Tooltip title="Delete" placement="bottom">
                <span>
                    <Button href="javascript:void(0)">
                        <span>
                            <DeleteOutlined fontSize="small" className="attachFileIcon" />
                        </span>
                    </Button>
                </span>
            </Tooltip>
        );
    }
    function deletePoEntryRow() {
        setIsLoading(true);
        let formData = {

            UserId: deleteRowData.userId,


            ModifiedBy: userDetails.userId,
            LoggedInUserId: userDetails.userId,
            LoggedInUser: userDetails.userId
            //ModifiedDate: date,
        }
        DeleteUser(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId,formData)
            .then((response) => {
                handleSnackOpen(response.responseMsg, "success");
                handleCloseDeleteDialog();
                getUsers()

            })
            .catch((error) => {
                handleSnackOpen(error, "error");
            });
    }
    //Updating the row
    const handleRowUpdate = (newData, oldData, resolve, reject) => {
        newData.ModifiedBy = userDetails.id;
        api.put('/updateUser', newData)
            .then(res => {
                const dataUpdate = [...data];
                if (res.data === 2) {
                    setData([...dataUpdate]);
                    handleSnackOpen("User is already exist.", "warning");
                    // resolve();
                    reject();
                }
                else {
                    const index = oldData.tableData.id;
                    dataUpdate[index] = newData;
                    setData([...dataUpdate]);
                    //resolve();
                    reject();
                    handleSnackOpen("User updated successfully.", "success");
                }
            })
            .catch(error => {
                // resolve();
                reject();
            })
    };

    //Deleting user
    const handleRowDelete = (oldData, resolve, reject) => {
        api.delete('/deleteUser/' + oldData.id)
            .then(res => {
                const dataDelete = [...data];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);
                setData([...dataDelete]);
                //resolve();  
                reject();
                handleSnackOpen("User deleted successfully.", "success");
            })
            .catch((error) => {
                //resolve();
                reject();
            });
    };

    const tableHeaderStyle = {
        backgroundColor: "var(--light-sand)",
        color: "var(--dark-blue)",
    };
    const tableOptions = {
        headerStyle: tableHeaderStyle,
        showTitle: false,
        draggable: false,
        pageSize: 5,
        pageSizeOptions: [5, 10, 25, 50],
        paginationPosition: "bottom",
        //paging: false,
        maxBodyHeight: '600px',
        // exportButton: true,
        // exportAllData: true
    };

    return (
        <div>

            <div className="App">
                <Grid container spacing={1}>
                    {/*<Grid item xs={12} className="d-flex jc-space-bt">*/}
                    {/*    <Grid item xs={9} className="text-left ml-1">*/}
                          
                    {/*    </Grid>*/}
                    {/*</Grid>*/}
                    <Grid item xs={12}>
                      {/*  <h2>User List</h2>*/}
                        <MaterialTable
                            title="User List"
                            //title={getTableTitle()}
                            columns={col}
                            data={data}
                            //icons={tableIcons}
                            icons={tableIconsInputScreen}
                            // isLoading={isLoader}
                            options={{
                                headerStyle: { background: '#d3d3d3' }, tableOptions,  searchFieldAlignment: "left",
                                toolbarButtonAlignment: "left", exportButton: { csv: true }, exportAllData: true,
                            }}
                            actions={[

                                {
                                    icon: GetEditButton,
                                    tisFreeAction: true,
                                    onClick: (e, rowData) => {
                                        //setOpenDia(true)
                                        setDialogSubmitBtn("Update")
                                        setCustDialogTitle("Update User");
                                        dialogDraftValuesFn(rowData);
                                    },
                                },
                                {
                                    icon: GetAddButton,
                                    isFreeAction: true,
                                    onClick: () => {
                                        setDialogSubmitBtn("Save");
                                        setCustDialogTitle("Add New User");
                                        setOpenDia(true);
                                    },
                                },
                                {
                                    icon: GetDeleteButton,
                                    tisFreeAction: false,
                                    onClick: (e, rowData) => {
                                        console.log("Row Data", rowData);
                                        //deletePoEntryRow(rowData);
                                        handleDeleteIconClick(rowData);
                                    },


                                },



                            ]}
                        />
                    </Grid>
                </Grid>
                <Dialog fullWidth
                    onClose={(event, reason) => {
                        if (reason !== "backdropClick") {
                            handleCloseDia();
                        }
                    }}
                    aria-labelledby="litigation-form--registration"
                    open={openDia}
                    maxWidth={"lg"}
                    disableEscapeKeyDown
                >
                    <DialogTitle id="responsive-dialog-title">{custDialogTitle}</DialogTitle>
                    <div style={{ position: "absolute", right: "1%" }}>
                        <Tooltip title="Close">
                            <IconButton onClick={handleCloseDia}>
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Grid container spacing={2}>









                                {/*<Grid item xs={12} sm={3}>*/}
                                {/*    <FormControl fullWidth>*/}
                                {/*        <InputLabel shrink htmlFor="bootstrap-input">*/}
                                {/*            DistCh*/}
                                {/*        </InputLabel>*/}
                                {/*        <BootstrapInput id="cutomer_po"*/}
                                {/*            name="cutomerPo"*/}
                                {/*            value={dist}*/}
                                {/*            onChange={handleChangeDist}*/}

                                {/*        />*/}
                                {/*    </FormControl>*/}
                                {/*</Grid>*/}



                                {/*<Grid item xs={12} sm={3}>*/}
                                {/*    <FormControl fullWidth>*/}
                                {/*        <InputLabel shrink htmlFor="bootstrap-input">*/}
                                {/*            Div SIGrp*/}
                                {/*        </InputLabel>*/}
                                {/*        <BootstrapInput id="cutomer_po"*/}
                                {/*            name="cutomerPo"*/}
                                {/*            value={div}*/}
                                {/*            onChange={handleChangeDiv}*/}

                                {/*        />*/}
                                {/*    </FormControl>*/}
                                {/*</Grid>*/}
                                {/*<Grid item xs={12} sm={3}>*/}
                                {/*    <FormControl fullWidth>*/}
                                {/*        <InputLabel shrink htmlFor="bootstrap-input">*/}
                                {/*            Name2*/}
                                {/*        </InputLabel>*/}
                                {/*        <BootstrapInput id="cutomer_po"*/}
                                {/*            name="cutomerPo"*/}
                                {/*            value={name2}*/}
                                {/*            onChange={handleChangeName2}*/}

                                {/*        />*/}
                                {/*    </FormControl>*/}
                                {/*</Grid>*/}
                                <Grid item xs={12} sm={4} md={3}>
                                    <TextField
                                        className="d-flex jc-flex-start mt-1 "
                                        fullWidth
                                        name="firstName"
                                        label="First Name"
                                        variant="filled"
                                        value={firstName}
                                        helperText={firstNameHelper}
                                        onChange={onChange}
                                        id="firstName"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} md={3}>
                                    <TextField
                                        className="d-flex jc-flex-start mt-1 "
                                        fullWidth
                                        name="lastName"
                                        label="Last Name"
                                        variant="filled"
                                        value={lastName}
                                        helperText={lastNameHelper}
                                        onChange={onChange}
                                        id="lastName"
                                    />
                                </Grid>

                                {/*<Grid item xs={12} sm={3}>*/}
                                {/*    <FormControl fullWidth>*/}
                                {/*        <InputLabel shrink htmlFor="bootstrap-input">*/}
                                {/*             Fisrt Name*/}
                                {/*        </InputLabel>*/}
                                {/*        <BootstrapInput id="firstName"*/}
                                {/*            name="firstName"*/}

                                {/*            // helperText={helperText}*/}
                                {/*            onChange={handleChangeFirstName}*/}
                                {/*            value={firstName}*/}
                                {/*            helperText={firstNameHelper}*/}
                                {/*        />*/}

                                {/*    </FormControl>*/}
                                {/*</Grid>*/}
                                {/*<Grid item xs={12} sm={3}>*/}
                                {/*    <FormControl fullWidth>*/}
                                {/*        <InputLabel shrink htmlFor="bootstrap-input">*/}
                                {/*            Last Name*/}
                                {/*        </InputLabel>*/}
                                {/*        <BootstrapInput id="lastName"*/}
                                {/*            name="cutomerPo"*/}
                                {/*            value={lastName}*/}
                                {/*            onChange={onChange}*/}
                                {/*            helperText={lastNameHelper}*/}
                                {/*        />*/}
                                {/*    </FormControl>*/}
                                {/*</Grid>*/}
                                
                                {/*<Grid item xs={12} sm={3}>*/}
                                {/*    <FormControl fullWidth>*/}
                                {/*        <InputLabel shrink htmlFor="bootstrap-input">*/}
                                {/*            CndTyp*/}
                                {/*        </InputLabel>*/}
                                {/*        <BootstrapInput id="cutomer_po"*/}
                                {/*            name="cutomerPo"*/}
                                {/*            value={cnd}*/}
                                {/*            onChange={handleChangeCnd}*/}

                                {/*        />*/}
                                {/*    </FormControl>*/}
                                {/*</Grid>*/}
                                {/*<Grid item xs={12} sm={3}>*/}
                                {/*    <FormControl fullWidth>*/}
                                {/*        <InputLabel shrink htmlFor="bootstrap-input">*/}
                                {/*            Role*/}
                                {/*        </InputLabel>*/}
                                {/*        <BootstrapInput id="cutomer_po"*/}
                                {/*            name="cutomerPo"*/}
                                {/*            value={role}*/}
                                {/*            onChange={handleChangeRole}*/}

                                {/*        />*/}
                                {/*    </FormControl>*/}
                                {/*</Grid>*/}
                                <Grid item xs={12} sm={4} md={3}>
                                    <TextField
                                        className="d-flex jc-flex-start mt-1 "
                                        fullWidth
                                        name="lastName"
                                        label="Email Id"
                                        variant="filled"
                                        value={email}
                                        helperText={emailHelper}
                                        onChange={onChange}
                                        id="email"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} md={3}>
                                    <TextField
                                        className="d-flex jc-flex-start mt-1 "
                                        fullWidth
                                        name="lastName"
                                        label="GID"
                                        variant="filled"
                                        value={gid}
                                        helperText={gidHelper}
                                        onChange={onChange}
                                        id="gid"
                                        disabled={gidDisable}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3} >
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input" >
                                            Role
                                        </InputLabel>
                                        <Select
                                            label="Technical Status"
                                            id=""
                                            name=""
                                            className=""
                                            fullWidth
                                            value={role}
                                            onChange={handleChangeRole}
                                           // helperText={roleHelper}
                                            input={<BootstrapInput />}
                                        >
                                            {itemsRole.map((item) => (
                                                <MenuItem key={item.roleId} value={item.roleId}>
                                                    {item.roleName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {/*<Grid*/}
                                {/*    item*/}
                                {/*    xs={12}*/}
                                {/*    md={4}*/}
                                {/*    lg={3}*/}
                                {/*    className="d-flex mt-2"*/}
                                {/*>*/}
                                {/*    <TextField*/}
                                {/*        select*/}
                                {/*        label="Role"*/}
                                {/*        id="role"*/}
                                {/*        name="Role"*/}
                                {/*        className=""*/}
                                {/*        fullWidth*/}
                                {/*        value={role}*/}
                                {/*        onChange={onChange}*/}
                                {/*        variant="filled"*/}
                                {/*        helperText={roleHelper}*/}
                                {/*    >*/}
                                {/*        <MenuItem value={0}>*/}
                                {/*            <em>Select</em>*/}
                                {/*        </MenuItem>*/}
                                {/*        {itemsRole.map((item) => (*/}
                                {/*            <MenuItem key={item.roleId}*/}
                                {/*                // disabled={} */}
                                {/*                value={item.roleId}>*/}
                                {/*                {item.roleName}*/}
                                {/*            </MenuItem>*/}
                                {/*        ))}*/}
                                {/*    </TextField>*/}
                                {/*</Grid>*/}
                                
                                {/*<Grid item xs={12} sm={3}>*/}
                                {/*    <FormControl fullWidth>*/}
                                {/*        <InputLabel shrink htmlFor="bootstrap-input">*/}
                                {/*            Email Id*/}
                                {/*        </InputLabel>*/}
                                {/*        <BootstrapInput id="email"*/}
                                {/*            name="cutomerPo"*/}
                                {/*            value={email}*/}
                                {/*            onChange={onChange}*/}
                                {/*            helperText={emailHelper}*/}
                                {/*        />*/}
                                {/*    </FormControl>*/}
                                {/*</Grid>*/}
                                
                                {/*<Grid item xs={12} sm={3}>*/}
                                {/*    <FormControl fullWidth>*/}
                                {/*        <InputLabel shrink htmlFor="bootstrap-input">*/}
                                {/*           GId*/}
                                {/*        </InputLabel>*/}
                                {/*        <BootstrapInput id="gid"*/}
                                {/*            name="cutomerPo"*/}
                                {/*            value={gid}*/}
                                {/*            onChange={onChange}*/}
                                {/*            helperText={gidHelper}*/}

                                {/*        />*/}
                                {/*    </FormControl>*/}
                                {/*</Grid>*/}
                                {/*<Grid item xs={12} sm={3}>*/}
                                {/*    <FormControl fullWidth>*/}
                                {/*        <InputLabel shrink htmlFor="bootstrap-input">*/}
                                {/*            CndUnit*/}
                                {/*        </InputLabel>*/}
                                {/*        <BootstrapInput id="cutomer_po"*/}
                                {/*            name="cutomerPo"*/}
                                {/*            value={cndUnit}*/}
                                {/*            onChange={handleChangeCndUnit}*/}

                                {/*        />*/}
                                {/*    </FormControl>*/}
                                {/*</Grid>*/}
                                <Grid item xs={12} sm={3} >
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input" >
                                            Value
                                        </InputLabel>
                                        <Select
                                            label="Technical Status"
                                            id=""
                                            name=""
                                            className=""
                                            fullWidth
                                            value={value}
                                            onChange={handleChangeValue}
                                            input={<BootstrapInput />}
                                        >
                                            {valueData.map((item) => (
                                                <MenuItem key={item.valueId} value={item.valueId}>
                                                    {item.value}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={3} >
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input" >
                                            Office
                                        </InputLabel>
                                        <Select
                                            label="Technical Status"
                                            id=""
                                            name=""
                                            className=""
                                            fullWidth
                                            value={office}
                                            onChange={handleChangeOffice}
                                            input={<BootstrapInput />}
                                        >
                                            {officeData.map((item) => (
                                                <MenuItem key={item.officeMasterId} value={item.officeMasterId}>
                                                    {item.officeMasterName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={3} >
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input" >
                                            Region
                                        </InputLabel>
                                        <Select
                                            label="Technical Status"
                                            id=""
                                            name=""
                                            className=""
                                            fullWidth
                                            value={region}
                                            onChange={handleChangeRegion}
                                            input={<BootstrapInput />}
                                        >
                                            {regionData.map((item) => (
                                                <MenuItem key={item.regionMasterId} value={item.regionMasterId}>
                                                    {item.regionMasterName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {/*<Grid item xs={12} sm={3} >*/}
                                {/*    <FormControl fullWidth>*/}
                                {/*        <InputLabel shrink htmlFor="bootstrap-input" >*/}
                                {/*            Roll*/}
                                {/*        </InputLabel>*/}
                                {/*        <Select*/}
                                {/*            label="Technical Status"*/}
                                {/*            id=""*/}
                                {/*            name=""*/}
                                {/*            className=""*/}
                                {/*            fullWidth*/}
                                {/*            value={roll}*/}
                                {/*            onChange={handleChangeRoll}*/}
                                {/*            input={<BootstrapInput />}*/}
                                {/*        >*/}
                                {/*            {rollData.map((item) => (*/}
                                {/*                <MenuItem key={item.rollId} value={item.rollId}>*/}
                                {/*                    {item.rollName}*/}
                                {/*                </MenuItem>*/}
                                {/*            ))}*/}
                                {/*        </Select>*/}
                                {/*    </FormControl>*/}
                                {/*</Grid>*/}
                                {/*<Grid item xs={12} sm={3}>*/}
                                {/*    <FormControl fullWidth>*/}
                                {/*        <InputLabel shrink htmlFor="bootstrap-input">*/}
                                {/*            Roll No*/}
                                {/*        </InputLabel>*/}
                                {/*        <BootstrapInput id="cutomer_po"*/}
                                {/*            name="cutomerPo"*/}
                                {/*            value={rollNo}*/}
                                {/*            onChange={handleChangeRollNo}*/}

                                {/*        />*/}
                                {/*    </FormControl>*/}
                                {/*</Grid>*/}
                                <Grid item xs={12} sm={3}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input">
                                            Contact Number
                                        </InputLabel>
                                        <BootstrapInput id="cutomer_po"
                                            name="cutomerPo"
                                            value={contact}
                                            onChange={handleChangeContact}

                                        />
                                    </FormControl>
                                </Grid>

                            </Grid>

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            type="submit"

                            className="pt-button--secondary"
                            id="addUserSave"
                            onClick={
                                dialogSubmitBtn === "Save" ? handleSaveForm : handleUpdateForm
                            }
                        >
                            {dialogSubmitBtn}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={deleteDialogOpen}
                    onClose={handleCloseDeleteDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="dialogTitleTxt">Delete User</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure to Delete this User ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={deletePoEntryRow}
                            className="pt-button--primary"
                        >
                            Ok
                        </Button>
                        <Button
                            onClick={handleCloseDeleteDialog}
                            className="pt-button--secondary"
                            autoFocus
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
                {isLoading ? <Loader loaderText="Loading...!" /> : <></>}
                <SnackbarCustom
                    open={open}
                    message={snackMessage}
                    alertType={alertType}
                    handleClose={handleClose}
                />
            </div>


            {/* <div>
                <MaterialTable
                    title="User Management"
                    data={staticData}
                    columns={columns}
                    // icons={tableIcons}
                    options={{
                        headerStyle: {
                            backgroundColor: "#00193C",
                            color: "#FFFFFF"
                        },
                        //exportButton: {
                        //    csv: true,
                        //    pdf: false
                        //},
                        //exportAllData: true
                    }}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                handleRowUpdate(newData, oldData, resolve, reject);
                            }),
                        onRowAdd: (newData) =>
                            new Promise((resolve, reject) => {
                                handleRowAdd(newData, resolve, reject);
                            }),
                        onRowDelete: (oldData) =>
                            new Promise((resolve, reject) => {
                                handleRowDelete(oldData, resolve, reject)
                            }),
                    }}
                >
                </MaterialTable>

            </div> */}

        </div>
    );
}